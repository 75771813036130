<template>
  <section class="py-6 py-lg-8" ref="policy">
    <div class="container">
      <div class="px-lg-8">
        <h2 class="content-title fs-1 text-center mb-8">股價及股利資訊</h2>
        <p class="text_indent">
          本公司為興櫃公司，依主管機關金管會證期局規定定期對投資大眾公開財務資訊，請直接連結至公開資訊觀測站<a
            class="new__link"
            href="http://mops.twse.com.tw/"
            >http://mops.twse.com.tw/</a
          >
          ，輸入股票代號 7767，即可取得本公司相關資料。
        </p>
        <p>&nbsp;</p>

        <strong>股價資訊</strong>
        <p>查詢路徑：櫃檯買賣中心/個股行情</p>
        <p>
          查詢網址：
          <a class="new__link" href="https://mis.tpex.org.tw/IB120STK.aspx">
            https://mis.tpex.org.tw/IB120STK.aspx</a
          >
        </p>
        <p>(公司代號 7767 → 資料年月 → 搜尋)</p>
        <p>&nbsp;</p>

        <strong>股利資訊</strong>
        <p>查詢路徑：公開資訊觀測站 / 股東會及股利 / 股利分派情形 – 經股東會確認</p>
        <p>
          查詢網址：
          <a class="new__link" href="http://mops.twse.com.tw/mops/web/t05st09"
            >http://mops.twse.com.tw/mops/web/t05st09</a
          >
        </p>
        <p>(公司代號 7767 → 資料年月 → 搜尋)</p>
      </div>
    </div>
  </section>
</template>

<script>
export default {};
</script>

<style>
.new__link {
  text-decoration: underline;
}
.text_indent {
  text-indent: 2rem;
}
p {
  font-weight: 300;
}
</style>
